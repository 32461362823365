import React from 'react'
import tw, { styled } from 'twin.macro'
import EmfImage from '@elements/EmfImage'
import { useBreakpoint } from '@hooks/useBreakpoint'
import Modal from '@elements/Modal'
import { useInView } from 'react-intersection-observer'
import parse from 'html-react-parser'

const Section = tw.section``
const ImageWrap = tw.div`relative`
const VideoWrap = tw.div`h-full w-full cursor-pointer`
const IframeWrap = tw.div`h-full w-full cursor-pointer`
const ModalIframeWrap = tw.div`aspect-w-11 aspect-h-16 md:aspect-w-16 md:aspect-h-9 2xl:aspect-none w-full max-w-[800px] min-h-[33%] 2xl:h-[500px] 2xl:w-full max-h-[450px]`
const ImageHeadlineWrap = tw.div`relative top-[60%] sm:col-span-6 order-[-1] sm:order-none`
const Headline = tw.p`sm:absolute sm:bottom-full w-6/12 sm:ml-offset-xs lg:ml-offset-lg`
const PlayIcon = ({ onClick, className }) => (
	<div onClick={onClick} className={className}>
		<svg width='83' height='100' viewBox='0 0 83 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M80.0055 44.8489C83.152 46.7681 83.2115 51.316 80.1162 53.3168L9.60764 98.8919C6.30542 101.026 1.94524 98.6898 1.89383 94.7581L0.726338 5.46693C0.674931 1.53526 4.97253 -0.914535 8.32943 1.13288L80.0055 44.8489Z'
				fill='white'
			/>
		</svg>
	</div>
)
const StyledPlayIcon = tw(PlayIcon)`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10`

const SocialWrap = styled.div`
	${tw`mb-[160px] min-w-[350px] w-full px-4 md:px-0 max-w-[410px] 4xl:max-w-[18vw] md:mb-16 mt-12 sm:mt-24 md:ml-[2vw] md:mt-8 lg:ml-10 xl:mb-0 xl:mt-12 xl:ml-16 4xl:ml-[3vw] 4xl:mt-[5vh] grid grid-cols-2 gap-4 4xl:gap-[1vh]`}
`
const SocialPlatform = styled.p`
	${tw`text-base text-white uppercase transition-colors font-futuraExtra tracking-1`}
`
const SocialPlatformButton = styled.a`
	${tw`min-h-[50px] min-w-[145px] xs:min-w-[167px] max-h-[50px] max-w-[197px] 4xl:max-w-[8vw] 4xl:h-[4vh] 4xl:max-h-[4vh] flex justify-center items-center cursor-pointer transition-colors`}
	background-color: ${({ platform }) => {
		if (platform.match(/facebook/i)) {
			return '#003899'
		}
		if (platform.match(/instagram/i)) {
			return '#B83093'
		}
		if (platform.match(/twitter/i)) {
			return '#00ACEE'
		}
		if (platform.match(/Tiktok/i) || platform.match(/Tik tok/i)) {
			return '#FE2C55'
		}
		if (platform.match(/Youtube/i) || platform.match(/YT/i)) {
			return '#FF0000'
		}
		return '#003899'
	}};
	&:hover {
		p {
			color: ${({ platform }) => {
				if (platform.match(/facebook/i)) {
					return '#003899'
				}
				if (platform.match(/instagram/i)) {
					return '#B83093'
				}
				if (platform.match(/twitter/i)) {
					return '#00ACEE'
				}
				if (platform.match(/Tiktok/i) || platform.match(/Tik tok/i)) {
					return '#FE2C55'
				}
				if (platform.match(/Youtube/i) || platform.match(/YT/i)) {
					return '#FF0000'
				}
				return '#003899'
			}};
		}
		background-color: white;
	}
`

const WpAcfFlowimagesBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesFlowImages
	const { socialmedia } = module
	const { isMobile } = useBreakpoint()
	const [currentYTPlaying, setCurrentYTPlaying] = React.useState(false)
	const [modalOpened, setModalOpened] = React.useState(false)

	const vid1Ref = React.useRef()
	const vid2Ref = React.useRef()
	const vid3Ref = React.useRef()
	const vid4Ref = React.useRef()

	const isVideoPlaying = (video) => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
	const videoHandle = (video) => {
		const playing = isVideoPlaying(video)
		const playIcon = video.previousSibling
		if (playing) {
			playIcon.style.display = 'block'
			video.pause()
		} else {
			playIcon.style.display = 'none'
			video.play()
		}
	}
	const getSocialLinks = (socialMediaList) => (
		<div className='flex justify-center w-full md:block'>
			<SocialWrap>
				{socialMediaList.map((item) => (
					<SocialPlatformButton key={item.link.url + item.platform} target='_blank' href={item.link.url} platform={item.platform}>
						<SocialPlatform>{item.platform}</SocialPlatform>
					</SocialPlatformButton>
				))}
			</SocialWrap>
		</div>
	)

	const ytHandle = (index) => {
		setModalOpened(true)
		setCurrentYTPlaying(index)
	}

	const getVideoId = (url) => {
		url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
		return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
	}

	const handleAutoplay = (state, vidref) => {
		if (!vidref.current) return
		if (state) {
			vidref.current.setAttribute('autoPlay', true)
			vidref.current.play()
		}
		if (!state) {
			vidref.current.pause()
			vidref.current.currentTime = 0
			vidref.current.setAttribute('autoPlay', false)
		}
	}
	const [ref1, isVisible1] = useInView({ threshold: 1 })
	const [ref2, isVisible2] = useInView({ threshold: 1 })
	const [ref3, isVisible3] = useInView({ threshold: 1 })
	const [ref4, isVisible4] = useInView({ threshold: 1 })

	React.useEffect(() => {
		isVisible1 ? handleAutoplay(true, vid1Ref) : handleAutoplay(false, vid1Ref)
		isVisible2 ? handleAutoplay(true, vid2Ref) : handleAutoplay(false, vid2Ref)
		isVisible3 ? handleAutoplay(true, vid3Ref) : handleAutoplay(false, vid3Ref)
		isVisible4 ? handleAutoplay(true, vid4Ref) : handleAutoplay(false, vid4Ref)
	}, [isVisible4, isVisible1, isVisible2, isVisible3])

	const [ref, isVisible] = useInView({ threshold: 1, triggerOnce: true })

	if (module.items?.length !== 4) return ''
	return (
		module.items?.length === 4 && (
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='relative flex flex-col emf-pb-spacing' ref={ref}>
				{isVisible && (
					<div className='relative flex flex-col justify-center grid-cols-12 gap-3 px-offset-xs sm:px-offset-lg sm:gap-0 sm:grid'>
						<ImageWrap className='z-0 xs:col-span-6 aspect-w-16 aspect-h-11 min-h-[33%] max-h-[450px]'>
							{module.items[0].image && <EmfImage src={module.items[0].image?.localFile} speed={0.05} moveDown={true} altText={module.items[0].image?.altText} />}
							{module.items[0].video && (
								<VideoWrap ref={ref1} onClick={() => videoHandle(vid1Ref.current)}>
									{!isVisible1 && <StyledPlayIcon />}
									<video ref={vid1Ref} muted playsInline src={module.items[0].video?.localFile?.publicURL} className='object-cover w-full h-full'></video>
								</VideoWrap>
							)}
							{module.items[0].youtube && (
								<IframeWrap onClick={() => ytHandle(0)}>
									<StyledPlayIcon />
									<EmfImage src={module.items[0].poster?.localFile} speed={0.08} moveDown={false} altText={module.items[0].poster?.altText} />
								</IframeWrap>
							)}
						</ImageWrap>
						<ImageHeadlineWrap>
							{module.headline && <Headline className='mb-offset-xs headline-h2'>{parse(module.headline)}</Headline>}
							<ImageWrap className='z-10 aspect-w-16 aspect-h-9 max-h-[620px]'>
								{module.items[1].image && <EmfImage src={module.items[1].image?.localFile} speed={0.08} moveDown={false} altText={module.items[1].image?.altText} />}
								{module.items[1].video && (
									<VideoWrap ref={ref2} onClick={() => videoHandle(vid2Ref.current)}>
										{!isVisible2 && <StyledPlayIcon />}
										<video ref={vid2Ref} muted playsInline src={module.items[1].video?.localFile?.publicURL} className='object-cover w-full h-full'></video>
									</VideoWrap>
								)}
								{module.items[1].youtube && (
									<IframeWrap onClick={() => ytHandle(1)}>
										<StyledPlayIcon />
										<EmfImage src={module.items[1].poster?.localFile} speed={0.08} moveDown={false} altText={module.items[1].poster?.altText} />
									</IframeWrap>
								)}
							</ImageWrap>
						</ImageHeadlineWrap>
						<ImageWrap className='z-30 top-0 col-start-2 col-end-7 aspect-w-2 aspect-h-2 sm:aspect-h-3 max-h-[450px] sm:max-h-[750px]'>
							{module.items[2].image && <EmfImage src={module.items[2].image?.localFile} speed={0.05} moveDown={false} altText={module.items[2].image?.altText} />}
							{module.items[2].video && (
								<VideoWrap ref={ref3} onClick={() => videoHandle(vid3Ref.current)}>
									{!isVisible3 && <StyledPlayIcon />}
									<video ref={vid3Ref} muted playsInline src={module.items[2].video?.localFile?.publicURL} className='object-cover w-full h-full'></video>
								</VideoWrap>
							)}
							{module.items[2].youtube && (
								<IframeWrap onClick={() => ytHandle(2)}>
									<StyledPlayIcon />
									<EmfImage src={module.items[2].poster?.localFile} speed={0.08} moveDown={false} altText={module.items[2].poster?.altText} />
								</IframeWrap>
							)}
						</ImageWrap>
						<ImageWrap className='absolute z-20 top-[23%] col-start-6 xs:col-span-5 aspect-w-16 aspect-h-10 sm:aspect-h-7 xs:max-h-[230px] lg:max-h-[400px]'>
							<div>
								{module.items[3].image && <EmfImage src={module.items[3].image?.localFile} speed={0.03} moveDown={false} altText={module.items[3].image?.altText} />}
								{module.items[3].video && (
									<VideoWrap ref={ref4} onClick={() => videoHandle(vid4Ref.current)}>
										{!isVisible4 && <StyledPlayIcon />}
										<video ref={vid4Ref} muted playsInline src={module.items[3].video?.localFile?.publicURL} className='object-cover w-full h-full'></video>
									</VideoWrap>
								)}
								{module.items[3].youtube && (
									<IframeWrap onClick={() => ytHandle(3)}>
										<StyledPlayIcon />
										<EmfImage src={module.items[3].poster?.localFile} speed={0.08} moveDown={false} altText={module.items[3].poster?.altText} />
									</IframeWrap>
								)}
								{!isMobile && !!socialmedia && getSocialLinks(socialmedia)}
							</div>
						</ImageWrap>
					</div>
				)}
				{isMobile && isVisible && !!socialmedia && getSocialLinks(socialmedia)}
				<Modal state={modalOpened} onClick={() => setModalOpened(false)}>
					{modalOpened && module.items[currentYTPlaying]?.youtube && (
						<ModalIframeWrap>
							{isVisible && (
								<iframe
									title={`EMF Racing ${getVideoId(module.items[currentYTPlaying]?.youtube)}`}
									src={`https://www.youtube-nocookie.com/embed/${getVideoId(module.items[currentYTPlaying]?.youtube)}?rel=0&autoplay=1`}
									frameBorder='0'
									className='!w-full !h-full '
									allow='autoplay; encrypted-media'
									allowFullScreen
								></iframe>
							)}
						</ModalIframeWrap>
					)}
				</Modal>
			</Section>
		)
	)
}
export default WpAcfFlowimagesBlock
